$themColor1: #001e72;
$themColor2: #f6942c;
@keyframes opacity-animation {
    from {
        opacity: 0;
        visibility: hidden;
    }
    to {
        opacity: 1;
        visibility: visible;
    }
}
.DetailComponent {
    width: calc(100% - 60px);
    margin: 30px auto;
    position: relative;
    .nav,
    .tab-content {
        box-shadow: 0px 5px 15px rgba(140, 152, 164, 0.1);
        position: relative;

        .tab-pane{

            display: none;
            visibility: hidden;
        }

        .tab-pane.show{
            display: block;
            
            animation: opacity-animation 0.3s;
            visibility: visible;

            opacity: 1;


            
        }
    }
    .nav {
        a {
            color: #000;
        }
        // height: 300vh;
        top: 20px;
        background: #fff;
    }
    .tab-content {
        padding: 40px;
        padding-left: 10%;
        line-height: 1.8;
        min-height: calc(100vh - 200px);
        background: #fff;
    }
    .side-nav {
        position: fixed;
        z-index: 1000;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        padding: 0;
        box-shadow: 0 5px 15px rgba(140, 152, 164, 0.5);
        font-size: 12px;
        // background: transparent;
        // width: 10em;
        padding: 10px 0;
        .nav-link {
            // width: 100%;
            // line-height: 1.4;
            height: auto;
            padding: 0;
            margin: 8px 10px;
            padding-left: 10px;
        }
        .nav-link.active,
        .show>.nav-link {
            background: transparent;
            color: $themColor1;
            position: relative;
            &::before {
                position: absolute;
                top: 50%;
                left: 0px;
                background: $themColor1;
                transform: translateY(-50%);
                width: 2px;
                height: 80%;
                display: block;
                content: "";
                border-radius: 6px;
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .DetailComponent {
        .side-nav {
            // position: fixed;
            // z-index: 1000;
            top: 50%;
            // left: 0;
            // background: transparent;
            // width: 10em;
            transform: translateY(-50%) translateX(-100%);
            width: 300px !important;
        }
        .tab-content {
            padding: 40px;
        }
    }
}