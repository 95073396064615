.banner {
    // height: 100vh;
    .carousel {
        text-align: center;
        .carousel-item {
            // height: 100%;
            // height: 500px;
            line-height: 500px;
        }
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
    .swiper-slide {
        // img {
        //     max-width: 100%;
        //     max-height: 100%;
        // }
        // text-align: center;
        // height: calc(100vh - 120px);
    }
    h2 {
        font-family: -webkit-pictograph;
        font-size: 30px;
        font-style: italic;
        text-align: center;
    }
    .swiper-container {
        padding-bottom: 40px;
    }
}