.submit_tip {
    cursor: pointer;
}

.send_bt .submit {
    width: 100%;
    float: left;
    font-size: 20px;
    color: #ffffff;
    text-align: center;
    background-color: #ff5a58;
    padding: 10px 0px;
    text-transform: uppercase;
    border-radius: 30px;
    cursor: pointer;
}