.ServicesComponent {
    // margin: 150px 0;
    .what_we_do_section_2 {
        float: none;
    }
    .lorem_text {
        height: 48px;
    }
    .box_main {
        margin: 15px 0;
    }
    .lorem_text {
        padding: 0 40px;
        // font-size: 14px;
    }
    .accounting_text {
        margin: 10px 0;
        // font-size: 18px;
    }
}