$themColor1: #001e72;
$themColor2: #f6942c;
.project_main {
    padding: 0;
    margin: 10px 0;
}

.project_section_2,
.client_section,
.team_section,
.what_taital,
.what_we_do_section,
.services_section {
    float: none;
    // background: none;
}

.project_section {
    margin: 0;
    // margin-top: 150px;
    // padding: 0;
    float: none;
}

@media (max-width: 575px) {
    .image_11 {
        width: 80%;
    }
    .client_section_2.layout_padding {
        padding-top: 0;
        li {
            padding-top: 20px;
        }
    }
}