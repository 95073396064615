$themColor1: #001e72;
$themColor2: #f6942c;
.header_section {
    display: flex;
    float: none;
    padding-top: 6%;
    .header_left {
        width: 100%;
    }
    .header_right {
        width: 100%;
    }
    .logo {
        width: 9%;
        margin-right: 8%;
    }
    // background: #fff;
    .navbar {
        background: #fff;
        padding: 10px 20px;
        // height: 100px;
        .nav-item {
            padding: 0 10px
        }
        // border-bottom: 1px solid #eee;
        box-shadow: 0 2px 24px rgba(0, 0, 0, .1);
        // border-bottom: 1px solid #ccc;
    }
    .close {
        width: 100%;
        border: none;
        text-align: center;
        font-size: 36px;
        font-weight: 300;
        // border-top: 1px #ccc solid;
        // padding-top: 20px;
        color: #999;
        margin-top: 10px;
        transform: rotateX(48deg);
    }
    .nav-link.active {
        color: $themColor1 !important;
        font-weight: 666;
    }
}

.weixin_box {
    cursor: pointer;
    position: relative;
    // margin-right: 10px;
    // width: 200px;
    text-align: center;
    display: flex;
    align-items: center;
    .weixin_img {
        position: absolute;
        width: 200px;
        // height: 200px;
        border: 1px $themColor1 solid;
        display: none;
        background: #fff;
        top: 0;
        left: 15px;
        transform: translate(-50%, 40px);
        P {
            line-height: 1.4;
            margin: 0px;
            margin-top: 10px;
        }
        // left: -50%;
    }
    &:hover .weixin_img {
        display: block;
    }
    .tel {
        margin-left: 20px;
        font-size: 18px;
    }
}

@media screen and (max-width: 992px) {
    .header_section {
        padding-top: 12%;
        flex-wrap: wrap;
    }
    .header_right {
        padding-top: 30px;
    }
    .header_section {
        .logo {
            width: 20%;
        }
    }
    .weixin_box {
        display: none;
    }
    .navbar-expand-lg .navbar-nav .nav-link {
        font-size: 18px;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
        height: auto!important;
    }
}