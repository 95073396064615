.partner-box {
    // display: flex;
    // flex-wrap: wrap;
    // margin-top: 60px;
    padding: 50px;
    .partner-item {
        width: 25%;
        height: 130px;
        // background: red;
    }
    .swiper-container {
        width: 100%;
        height: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;
        height: 180px;
        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
        border: 1px solid #ccc;
        img {
            max-width: 80%;
            max-height: 80%;
        }
    }
}

@media screen and (max-width: 600px) {
    .partner-box {
        .swiper-slide {
            height: 80px;
        }
    }
}